








import mixins from 'vue-typed-mixins'
import DialogMixins from '@/components/dialogs/mixins/DialogMixins'

export default mixins(DialogMixins).extend({
  name: 'BookingServiceDescriptionDialog',
  data () {
    return {
      description: null as string | null
    }
  },
  computed: {
    getDescription (): string | null {
      if (this.description) {
        return this.replaceUrlsWithLinks(this.description)
      }

      return null
    }
  },
  created () {
    this.description = this.dialog.data.description
  }
})
